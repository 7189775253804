.nav-logo-i {
  width: 6rem;
  position: relative;
}

/* navbar side components */
.nav-side-components svg {
  font-size: 1.5rem;
  color: black;
}

/* navbar address sec */

.nav-add-logo svg {
  font-size: 1.7rem;
  margin-right: 0.5rem;
  color: black;
}
.nav-add-h3 {
  font-size: 1rem;
  color: black;
  letter-spacing: 0.5px;
  line-height: 20px;
}
.nav-add-p {
  font-size: 0.6rem;
  border-bottom: 1px dotted blue;
}
.nav-add-p svg {
  color: black;
  position: relative;
  top: 3px;
  font-size: 0.825rem;
}

/* navbar search category  */
.search-popular-categories {
  align-items: center;
}
.popular-categories-list {
  display: grid;
  width: 90%;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 1.5rem;
  margin-top: 1.5rem;
}
.popular-categories-list-item {
  text-align: center;
  height: 5.5rem;
  margin-bottom: 1.8rem;
}
.categories-logo {
  border-radius: 20px;
  width: 5rem;
  opacity: 1;
  border-radius: 5px;
}

.nav-search-category-close-btn {
  z-index: 2;
  font-size: 1.7rem;
  color: black;
  display: none;
}

/* navbar profile section css */

.nav-p-container {
  width: 100%;
  padding: 1rem;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.219);
  background-color: white;
}
.nap-p-container-head {
  gap: 0.5rem;
  border-bottom: 1px solid var(--color-blue);
  padding-bottom: 1rem;
}
.nav-p-drp-name {
  font-weight: 900;
}
.nav-p-cont-main {
  gap: 0.8rem;
  border-bottom: 1px solid var(--color-blue);
  padding-block: 1rem;
  transition: 1s all;
}
.nav-p-cont-main-link {
  color: rgb(32, 32, 32);
  transform: translateX(0);
  transition: 0.4s all;
}
.nav-p-cont-main-link:hover {
  text-decoration: none;
  transform: translateX(6px);
  font-weight: 900;
  color: var(--color-blue);
}
.nav-p-cont-main-logout {
  padding-top: 0.7rem;
}

@media screen and (max-width: 768px) {
  .nav-logo-i {
    height: 4rem;
    width: 5rem;
  }

  .nav-search-category-close-btn {
    display: inline-block;
  }

  .popular-categories-list {
    margin-top: 1.5rem;
    width: 100%;
  }
  .popular-categories-list-item {
    height: auto;
    margin-bottom: 0rem;
  }
  .categories-logo {
    width: 4rem;
  }
  .nav-hidden {
    display: none;
  }

  /* nav address */

  .nav-add-logo svg {
    font-size: 1.4rem;
    margin-right: 0.3rem;
  }
  .nav-add-h3 {
    font-size: 1.1rem;
  }
  .nav-add-p {
    font-size: 0.6rem;
  }
  .nav-add-p svg {
    color: black;
  }
  /* navbar side components */
  .nav-side-components svg {
    font-size: 1.2rem;
  }
}
